import { template as template_7d1b30ec1fa044a19b0c783fa885d44f } from "@ember/template-compiler";
const SidebarSectionMessage = template_7d1b30ec1fa044a19b0c783fa885d44f(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
