import { template as template_f9a9981421e24065a832ed1d86181c03 } from "@ember/template-compiler";
const WelcomeHeader = template_f9a9981421e24065a832ed1d86181c03(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
